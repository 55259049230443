import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
 
<path d="M2234 3027 c-2 -7 -3 -85 -2 -172 l3 -160 43 -3 42 -3 0 175 0 176
-40 0 c-24 0 -43 -5 -46 -13z"/>
<path d="M2873 3032 c-17 -11 -16 -324 1 -334 6 -4 25 -8 42 -8 l31 0 2 175 2
175 -33 0 c-18 0 -38 -4 -45 -8z"/>
<path d="M1855 3014 c-86 -30 -125 -157 -72 -235 48 -73 190 -105 265 -59 42
26 50 25 54 -2 2 -16 11 -24 29 -26 l27 -3 1 95 2 96 -85 0 c-88 0 -102 -6
-91 -41 5 -15 14 -20 33 -18 50 6 35 -37 -17 -51 -80 -21 -145 7 -155 67 -9
58 5 95 45 116 44 23 122 17 154 -13 14 -14 35 -20 64 -20 l43 0 -18 28 c-26
39 -37 49 -79 66 -46 20 -146 20 -200 0z"/>
<path d="M2503 3023 c-12 -2 -41 -21 -64 -42 -39 -35 -43 -42 -47 -98 -5 -70
11 -109 60 -146 65 -48 171 -54 233 -13 15 10 28 16 30 14 1 -2 8 -13 14 -25
12 -24 50 -32 52 -10 0 6 3 35 5 62 3 28 2 65 -1 83 l-6 33 -82 -3 c-80 -3
-82 -4 -82 -28 0 -22 5 -25 38 -28 43 -4 47 -15 12 -38 -30 -20 -120 -23 -153
-5 -32 17 -48 79 -32 126 9 28 21 41 48 52 45 19 123 9 148 -18 20 -22 94 -27
94 -6 0 21 -44 64 -84 81 -36 15 -129 20 -183 9z"/>
<path d="M2097 2603 c-9 -8 -9 -306 -1 -327 6 -16 39 -21 68 -10 13 5 16 25
16 115 0 60 3 109 8 109 4 0 36 -52 70 -115 l63 -115 60 0 59 0 0 176 0 175
-42 -3 -43 -3 -2 -100 c-1 -55 -2 -109 -2 -120 -1 -12 -21 16 -51 69 -28 49
-50 90 -50 93 0 2 -10 17 -22 33 -19 27 -28 30 -73 30 -29 0 -55 -3 -58 -7z"/>
<path d="M2530 2434 l0 -175 43 3 42 3 3 120 3 119 21 -34 c12 -19 35 -60 51
-90 70 -127 57 -115 125 -118 l62 -3 0 176 0 176 -42 -3 -43 -3 -5 -115 -5
-115 -21 35 c-12 19 -43 72 -69 118 l-48 82 -58 0 -59 0 0 -176z"/>
<path d="M2975 2597 c-3 -7 -4 -85 -3 -172 l3 -160 139 -3 c148 -3 154 -1 151
46 l-1 27 -95 2 c-51 1 -99 2 -104 2 -13 1 -15 64 -2 72 5 4 31 5 58 4 27 -2
50 0 50 4 9 44 6 51 -25 52 -17 1 -35 1 -40 0 -28 -6 -51 10 -51 34 0 14 5 25
10 25 6 1 51 2 100 3 l90 2 0 35 0 35 -138 3 c-106 2 -139 0 -142 -11z m152
-143 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M3470 2603 c-41 -6 -108 -44 -125 -69 -24 -36 -34 -100 -23 -143 20
-80 91 -124 200 -123 57 0 75 5 113 29 29 18 50 41 59 63 l14 35 -41 3 c-26 2
-43 -1 -46 -9 -20 -60 -146 -74 -202 -23 -24 21 -26 100 -4 131 18 26 78 45
114 37 47 -10 79 -26 85 -40 6 -16 86 -17 86 -1 0 14 -22 51 -39 65 -44 34
-131 55 -191 45z"/>
<path d="M3746 2601 c-3 -5 -5 -21 -4 -35 3 -30 22 -39 76 -34 l37 3 5 -135 5
-135 37 -3 c47 -4 50 6 46 156 -2 70 1 112 7 113 6 0 30 1 55 2 44 2 45 2 48
40 l3 37 -155 0 c-85 0 -157 -4 -160 -9z"/>
<path d="M899 2591 c-159 -31 -204 -210 -76 -305 34 -25 156 -35 211 -16 37
12 106 80 106 105 0 29 -67 19 -100 -15 -33 -34 -67 -45 -117 -37 -52 9 -81
32 -89 72 -7 39 -1 71 19 98 27 38 156 40 183 3 6 -8 29 -18 52 -22 38 -6 42
-5 42 14 0 24 -41 66 -85 86 -40 18 -101 25 -146 17z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
